import { ApolloLink } from '@apollo/client';
import * as AbsintheSocket from '@absinthe/socket';
import { createAbsintheSocketLink } from '@absinthe/socket-apollo-link';
import _ from 'lodash';
import Observable from 'zen-observable';

import PhoenixSocket from './phoenixSocket';
import { getBearerToken, isNullOrWhitespace } from './util';

const MIN_HIDDEN_DURATION_FOR_RESET = 2 * 60 * 1000; // 2 minutes

function removeRootTypename(operation) {
  const updater = selections => [_.first(selections)];

  return _.update(
    operation,
    'query.definitions[0].selectionSet.selections',
    updater
  );
}

export default class PhoenixChannelLink extends ApolloLink {
  constructor(uri, endpointName, params) {
    super();

    this.uri = uri;
    this.endpointName = endpointName;
    this.params = params;
    this.getLink();

    this.lastVisibilityChange = new Date();
    document.addEventListener('visibilitychange', () => {
      try {
        if (
          !document.hidden &&
          new Date() - this.lastVisibilityChange > MIN_HIDDEN_DURATION_FOR_RESET
        ) {
          console.debug('resetting socket connection');
          this.reset();
        }
        this.lastVisibilityChange = new Date();
      } catch (e) {
        console.log(
          'there was an error establishing a pheonix socket conection, reason:',
          e
        );
      }
    });
  }

  request(operation) {
    return new Observable(observer => {
      const subscriptionPromise = this.subscribeToRequest(operation, observer);

      return () => subscriptionPromise.then(({ unsubscribe }) => unsubscribe());
    });
  }

  async subscribeToRequest(operation, observer) {
    let clearOnReconnect = () => {};
    const { onReconnect, onReconnectDelay } = operation.variables;

    const link = await this.getLink();
    const observable = link.request(removeRootTypename(operation));

    if (onReconnect) {
      const socket = await this.getSocket();
      clearOnReconnect = socket.onReconnect(onReconnect, onReconnectDelay);
    }

    const subscription = observable.subscribe({
      next: resp =>
        observer.next({
          ...resp,
          data: {
            ...resp.data,
            __typename: 'RootQueryType',
          },
        }),
    });

    return {
      ...subscription,
      unsubscribe: () => {
        clearOnReconnect();
        subscription.unsubscribe();
      },
    };
  }

  async getLink() {
    if (!this._link) {
      const socket = await this.getSocket();
      this._link = createAbsintheSocketLink(AbsintheSocket.create(socket));
    }

    return this._link;
  }

  async getSocket() {
    const bearerToken = getBearerToken();

    if (!this._socket) {
      const authKey = 'authorization';
      let params = {
        vsn: '1.0.0',
        ...this.params,
      };

      if (!isNullOrWhitespace(bearerToken)) {
        params[authKey] = bearerToken;
      }

      this._socket = await PhoenixSocket.create(this.uri, this.endpointName, {
        authKey,
        params,
      });
    }

    return this._socket;
  }

  async reset() {
    const socket = await this.getSocket();
    if (socket.conn) socket.abnormalClose('reset');
  }
}
